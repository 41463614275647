import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import AnimatedHome from "./AnimatedHome";
import ProductPage from "./ProductPage";
import News from "./News";
import Footer from "./Footer";
import AboutUs from "./About";
import Login from "./Login";
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<AnimatedHome />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/news" element={<News />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
