import { motion } from "framer-motion"
import { Leaf, Heart, Globe } from "lucide-react"

export default function AboutUs() {
  const values = [
    {
      icon: Leaf,
      title: "Sustainability",
      description: "We're committed to using eco-friendly packaging and sourcing ingredients responsibly."
    },
    {
      icon: Heart,
      title: "Quality",
      description: "We never compromise on the quality of our ingredients or the taste of our cookies."
    },
    {
      icon: Globe,
      title: "Community",
      description: "We support local farmers and give back to our community through various initiatives."
    }
  ]

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <motion.header
        className="bg-[#4a3728] text-white py-20 px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">About Iven</h1>
          <p className="text-xl md:text-2xl">Crafting Delicious Moments Since 2010</p>
        </div>
      </motion.header>

      <main className="container mx-auto px-4 py-12">
        <motion.section className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-6 text-center">Our Story</h2>
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="md:w-1/2">
              <p className="text-lg mb-4">
                Iven was born out of a passion for creating delicious, healthy cookies that bring joy to people's lives. Our journey began in a small kitchen in Ulaanbaatar, where our founder experimented with unique flavor combinations and wholesome ingredients.
              </p>
              <p className="text-lg">
                Today, we've grown into a beloved brand, known for our innovative cookie creations that blend traditional Mongolian flavors with modern culinary techniques. Our commitment to quality and sustainability has remained unchanged since day one.
              </p>
            </div>
            <div className="md:w-1/2">
              <img
                src="/placeholder.svg?height=400&width=600"
                alt="Iven founder in the kitchen"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </motion.section>

        <motion.section className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-6 text-center">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={value.title}
                className="bg-white p-6 rounded-lg shadow-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <value.icon className="w-12 h-12 text-[#d84c3e] mb-4" />
                <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                <p>{value.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-6 text-center">Our Process</h2>
          <div className="bg-white p-8 rounded-lg shadow-md">
            <ol className="relative border-l border-gray-200">
              <motion.li className="mb-10 ml-6" {...fadeInUp}>
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#8bc34a] rounded-full -left-4 ring-4 ring-white">
                  1
                </span>
                <h3 className="font-semibold text-lg mb-1">Ingredient Selection</h3>
                <p className="text-gray-700">We carefully source the finest ingredients, prioritizing local and organic options whenever possible.</p>
              </motion.li>
              <motion.li className="mb-10 ml-6" {...fadeInUp}>
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#d84c3e] rounded-full -left-4 ring-4 ring-white">
                  2
                </span>
                <h3 className="font-semibold text-lg mb-1">Artisanal Baking</h3>
                <p className="text-gray-700">Our skilled bakers craft each cookie with care, using time-honored techniques and innovative recipes.</p>
              </motion.li>
              <motion.li className="ml-6" {...fadeInUp}>
                <span className="absolute flex items-center justify-center w-8 h-8 bg-[#4a3728] rounded-full -left-4 ring-4 ring-white">
                  3
                </span>
                <h3 className="font-semibold text-lg mb-1">Quality Control</h3>
                <p className="text-gray-700">Every batch undergoes rigorous testing to ensure it meets our high standards of taste and quality.</p>
              </motion.li>
            </ol>
          </div>
        </motion.section>

      </main>
    </div>
  )
}