import { useState } from 'react'
import { ArrowLeft, ShoppingCart } from 'lucide-react'
import { Link } from 'react-router-dom';
import { Button } from "src/@/components/ui/button"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/@/components/ui/accordion"
import { Input } from 'src/@/components/ui/input';

export default function Component() {
  const [quantity, setQuantity] = useState(1)
  const [currentImage, setCurrentImage] = useState(0)

  // This would typically come from a database or API
  const product = {
    id: '1',
    name: '9 Fruits cracker',
    description: 'A delicious and healthy snack made with 9 different fruits. Perfect for on-the-go snacking or as a nutritious treat for kids and adults alike.',
    price: 9.99,
    images: [
      '/placeholder.svg?height=600&width=600&text=Main+Image',
      '/placeholder.svg?height=600&width=600&text=Side+View',
      '/placeholder.svg?height=600&width=600&text=Ingredients',
      '/placeholder.svg?height=600&width=600&text=Packaging',
    ],
    longDescription: 'Our 9 Fruits cracker is a delightful blend of nature\'s best offerings. Each bite is packed with the goodness of nine carefully selected fruits, providing a burst of flavor and essential nutrients. These crackers are not just tasty; they\'re a smart choice for health-conscious snackers.',
    information: [
      { label: 'Ingredients', value: 'Wheat flour, fruit blend (apple, banana, strawberry, blueberry, raspberry, blackberry, cherry, peach, apricot), sugar, vegetable oil, salt, natural flavors.' },
      { label: 'Allergens', value: 'Contains wheat. May contain traces of nuts and milk.' },
      { label: 'Storage', value: 'Store in a cool, dry place. Once opened, consume within 5 days.' },
      { label: 'Origin', value: 'Made in USA' },
    ]
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Link href="/" className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-4">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Home
      </Link>
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <div className="mb-4">
            <img 
              src={product.images[currentImage]} 
              alt={`${product.name} - View ${currentImage + 1}`}
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="flex space-x-2 overflow-x-auto">
            {product.images.map((image, index) => (
              <button
                key={index}
                onClick={() => setCurrentImage(index)}
                className={`flex-shrink-0 ${currentImage === index ? 'ring-2 ring-blue-500' : ''}`}
              >
                <img
                  src={image}
                  alt={`${product.name} - Thumbnail ${index + 1}`}
                  className="w-20 h-20 object-cover rounded-md"
                />
              </button>
            ))}
          </div>
        </div>
        <div>
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-xl mb-4">${product.price.toFixed(2)}</p>
          <p className="mb-6">{product.description}</p>
          <div className="flex items-center mb-4">
            <label htmlFor="quantity" className="mr-2">Quantity:</label>
            <Input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
              className="w-20 mr-4"
            />
            <Button className="flex items-center">
              <ShoppingCart className="mr-2 h-4 w-4" />
              Add to Cart
            </Button>
          </div>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="description">
              <AccordionTrigger>Product Description</AccordionTrigger>
              <AccordionContent>
                {product.longDescription}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="information">
              <AccordionTrigger>Product Information</AccordionTrigger>
              <AccordionContent>
                <ul className="space-y-2">
                  {product.information.map((info, index) => (
                    <li key={index} className="flex flex-col">
                      <span className="font-semibold">{info.label}:</span>
                      <span>{info.value}</span>
                    </li>
                  ))}
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  )
}