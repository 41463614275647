import { motion } from "framer-motion"
import { useState } from "react"

const products = [
  {
    name: "MELLOW AND FRAGRAND CHOCOLATE COOKIES",
    description: "Орц, найрлага: Элсэн чихэр, эрдэнэ шишийн гурил, буудааны гурил, далдуу тос, төмсний цардуул, нунтаг какао, цардуул, ванилийн ханд, глюкозын сироп, давс, хөөлгөгч, бэхжүүлэгч. Өндөг, сүү, самар агуулсан.",
    storage: "Хадгалах нөхцөл: Нарны шууд тусгал, чийг шилээс хол, сэрүүн, хуурай газар хадгална.",
    image: "/images/图片_20240703132336.jpg",
    color: "bg-[#4a3728]",
    textColor: "text-white"
  },
  {
    name: "MILK CHOCOLATE COOKIES",
    description: "Орц, найрлага: Элсэн чихэр, эрдэнэ улаан буудайн гурил, лактоз, шар сүүний нунтаг, мальтодекстрин, фруктоз, хуурай крем, фосфолипид, хүнсний сода, аммонийн устөрөгчийн карбонат, нимбэгний хүчил, алимны хүчил, ваниль, ургамлын тос, төмсний цардуул, нунтаг какао, цардуул, ванилийн ханд, глюкозын сироп, давс, хөөлгөгч, амт оруулагч, бэхжүүлэгч. Өндөг, сүү, самар агуулсан.",
    storage: "Хадгалах нөхцөл: Нарны шууд тусгал, чийгшилээс хол, сэрүүн, хуурай газар хадгална.",
    image: "/images/图片_20240703132354.jpg",
    color: "bg-[#d84c3e]",
    textColor: "text-white"
  },
  {
    name: "MATCHA CHOCOLATE COOKIES",
    description: "Орц, найрлага: Элсэн чихэр, ногоон цайны нунтаг, улаан буудайн гурил, далдуу модны тос, мальтодекстрин, фруктоз, хуурай крем, фосфолипид, хүнсний сода, аммонийн устөрөгчийн карбонат, нимбэгний хүчил, алимны хүчил, ваниль, ургамлын тос, төмсний цардуул, нунтаг какао, цардуул, ванилийн ханд, глюкозын сироп, давс, хөөлгөгч, амт оруулагч, бэхжүүлэгч. Өндөг, сүү, самар агуулсан байж болно.",
    storage: "Хадгалах нөхцөл: Нарны шууд тусгал, чийгшилээс хол, сэрүүн, хуурай газар хадгална.",
    image: "/images/图片_20240703132349.jpg",
    color: "bg-[#8bc34a]",
    textColor: "text-white"
  }
]

export default function Component() {
  const [selectedProduct, setSelectedProduct] = useState(0)

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-1 bg-gray-100">
      <h1 className="text-4xl font-bold mb-8 text-center">Iven Cookie Collection</h1>
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-col md:flex-row">
          <motion.div
            className="w-full md:w-1/2 p-8 flex items-center justify-center"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.img
              src={products[selectedProduct].image}
              alt={products[selectedProduct].name}
              className="w-64 h-auto object-contain"
              key={selectedProduct}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
          <motion.div
            className={`w-full md:w-1/2 p-8 ${products[selectedProduct].color} ${products[selectedProduct].textColor}`}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.h2
              className="text-2xl font-bold mb-4"
              key={`title-${selectedProduct}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {products[selectedProduct].name}
            </motion.h2>
            <motion.p
              className="mb-4"
              key={`description-${selectedProduct}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              {products[selectedProduct].description}
            </motion.p>
            <motion.p
              className="font-semibold"
              key={`storage-${selectedProduct}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {products[selectedProduct].storage}
            </motion.p>
          </motion.div>
        </div>
      </div>
      <div className="flex justify-center mt-8 space-x-4">
        {products.map((product, index) => (
          <motion.button
            key={index}
            className={`w-4 h-4 rounded-full ${
              index === selectedProduct ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setSelectedProduct(index)}
            aria-label={`View ${product.name}`}
          />
        ))}
      </div>
    </div>
  )
}