import React, { useState, useEffect, useCallback} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Card, CardContent } from "./@/components/ui/card"
import { Button } from "./@/components/ui/button"
import AnimatedProductShowcase from './AnimatedProductShowcase'

const AnimatedHome = () => {
  const [imageSize, setImageSize] = useState({ width: '100%', height: 'auto' });
  const [currentDeal, setCurrentDeal] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const updateImageSize = () => {
      const vh = window.innerHeight;
      const vw = window.innerWidth;
      setImageSize({
        width: Math.min(vw * 0.9, 1200),
        height: vh * 0.7,
      });
    };

    window.addEventListener('resize', updateImageSize);
    updateImageSize();

    return () => window.removeEventListener('resize', updateImageSize);
  }, []);

  const products = [
    { name: '9 Fruits cracker', image: '/images/9-fruits-front-preview.png', color: '#FFD1DC' },
    { name: '9 Veggies cracker', image: '/images/9-veggies-front-preview.png', color: '#E0FFE0' },
    { name: '3 Quinoa', image: '/images/3-quinoa-front-preview.png', color: '#FFFDD0' },
  ];
  const deals = [
    { title: "Summer Special", description: "Get 20% off on all fruit crackers", image: "/placeholder.svg?height=200&width=300" },
    { title: "Veggie Delight", description: "Buy 2 veggie crackers, get 1 free", image: "/placeholder.svg?height=200&width=300" },
    { title: "Quinoa Bonanza", description: "50% off on second quinoa purchase", image: "/placeholder.svg?height=200&width=300" },
    { title: "Healthy Mix", description: "Free shipping on orders over $50", image: "/placeholder.svg?height=200&width=300" },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const productVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100
      }
    }
  };

  const nextSlide = useCallback(() => {
    setCurrentDeal((prevDeal) => (prevDeal + 1) % deals.length)
  }, [deals.length])

  useEffect(() => {
    let intervalId;

    if (!isHovered) {
      intervalId = setInterval(() => {
        nextSlide()
      }, 5000) // Change slide every 5 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [isHovered, nextSlide]);

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative mb-16 h-[70vh] overflow-hidden rounded-lg"
      >
        <img
          src="/images/Screenshot 2024-07-03 131817.png"
          alt="Iven Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-4xl md:text-6xl font-bold mb-4 text-center"
          >
            Welcome to Iven
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-xl md:text-2xl mb-8 text-center"
          >
            Шинэлэг, Шимтэй бас Эрүүл амт
          </motion.p>
        </div>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true, amount: 0.8 }}
        className="mb-16"
      >
        <h2 className="text-3xl font-bold mb-8 text-center">Recent Good Deals</h2>
        <div 
          className="w-full max-w-3xl mx-auto"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Card>
            <CardContent className="p-6">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentDeal}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col items-center"
                >
                  <img 
                    src={deals[currentDeal].image} 
                    alt={deals[currentDeal].title} 
                    className="w-full h-48 object-cover mb-4 rounded-md"
                  />
                  <h3 className="text-xl font-semibold mb-2">{deals[currentDeal].title}</h3>
                  <p className="text-sm text-center mb-4">{deals[currentDeal].description}</p>
                  <Link href={`/product/${deals[currentDeal].id}`}>
                    <Button>Shop Now</Button>
                  </Link>
                </motion.div>
              </AnimatePresence>
            </CardContent>
          </Card>
          <div className="flex justify-center mt-4">
            {deals.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentDeal(index)}
                className={`w-3 h-3 rounded-full mx-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  currentDeal === index ? 'bg-blue-500' : 'bg-gray-300'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </motion.div>
      <AnimatedProductShowcase />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true, amount: 0.4 }}
        className="mt-16"
      >
        <h2 className="text-3xl font-bold mb-8 text-center">Celebrate with Iven</h2>
        <p className="text-xl mb-8 text-center">The perfect healthy snacks for everyday, or the perfect gift for your friends and family.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {products.map((product, index) => (
            <motion.div
              key={product.name}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <img src={product.image} alt={product.name} className="w-full h-64 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
                <Link  to={`/product`}>
                {/* <Link href={`/product/${product.id}`}> */}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    className="bg-blue-500 text-white px-4 py-2 rounded-full font-semibold"
                  >
                    Shop Now
                  </motion.button>
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default AnimatedHome;