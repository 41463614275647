import React from 'react'
import { motion } from 'framer-motion'
import { Facebook, Heart, MessageCircle, Share2 } from 'lucide-react'

const posts = [
  {
    id: 1,
    image: '/placeholder.svg?height=300&width=300',
    content: 'Introducing our new summer collection of colorful cupcakes! 🧁🌈',
    likes: 120,
    comments: 25,
    shares: 10
  },
  {
    id: 2,
    image: '/placeholder.svg?height=300&width=300',
    content: 'Our best-selling strawberry donuts are back in stock! 🍓🍩',
    likes: 85,
    comments: 15,
    shares: 5
  },
  {
    id: 3,
    image: '/placeholder.svg?height=300&width=300',
    content: 'Celebrate with our new birthday cake flavors! 🎂🎉',
    likes: 150,
    comments: 30,
    shares: 12
  },
  {
    id: 4,
    image: '/placeholder.svg?height=300&width=300',
    content: 'Try our vegan-friendly cookies today! 🍪🌱',
    likes: 95,
    comments: 18,
    shares: 8
  },
  {
    id: 5,
    image: '/placeholder.svg?height=300&width=300',
    content: 'Limited time offer: Buy one get one free on all muffins! 🧁2️⃣',
    likes: 200,
    comments: 40,
    shares: 25
  },
  {
    id: 6,
    image: '/placeholder.svg?height=300&width=300',
    content: 'New flavor alert: Matcha green tea macarons! 🍵🇫🇷',
    likes: 110,
    comments: 22,
    shares: 15
  }
]

const NewsPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 text-gray-800">Iven News</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <motion.div
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <img src={post.image} alt="" className="w-full h-64 object-cover" />
              <div className="p-6">
                <p className="text-gray-700 mb-4">{post.content}</p>
                <div className="flex items-center justify-between text-gray-500">
                  <div className="flex items-center space-x-2">
                    <Heart className="w-5 h-5" />
                    <span>{post.likes}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <MessageCircle className="w-5 h-5" />
                    <span>{post.comments}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Share2 className="w-5 h-5" />
                    <span>{post.shares}</span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <a
            href="https://www.facebook.com/ivenmongolia"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Facebook className="w-5 h-5 mr-2" />
            Visit Our Facebook Page
          </a>
        </div>
      </div>
    </div>
  )
}

export default NewsPage