import logoImage from "./IvenBlue.svg";
import React, { useState } from "react";
import { ShoppingBag, User, Search, Menu, X } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import "./Navbar.css";

const NavBar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMegaMenu, setActiveMegaMenu] = useState(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const menuItems = [
    {
      name: "Products",
      megaMenu: [
        {
          title: "Crakers",
          items: ["Healthy crackers", "Fruits Crackers", "Flavor Crackers"],
        },
        { title: "Snacks", items: ["Real Fruits", "Healthy", "Nuts"] },
        { title: "Candies", items: ["Marshmallows", "Gummy", "No Sugar"] },
      ],
    },
    {
      name: "Order",
      megaMenu: [
        {
          title: "Custom Orders",
          items: ["Design Your Own", "Bulk Orders", "Corporate Gifts"],
        },
        { title: "Shipping", items: ["Domestic", "International", "Express"] },
        { title: "Returns", items: ["Policy", "Process", "Exchange"] },
      ],
    },
    { name: "News", megaMenu: [], path: "/news" },
    { name: "Our Team", megaMenu: [] },
    { name: "About Us", megaMenu: [], path: "/about" },
  ];

  const handleMenuItemClick = (item) => {
    if (item.path) {
      navigate(item.path);
    }
    // Close mobile menu if it's open
    setIsMenuOpen(false);
  };

  const handleUserIconClick = () => {
    navigate("/login");
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-content">
          <div className="navbar-left">
            <Link to="/" className="navbar-logo">
              <img src={logoImage} alt="Company Logo" className="logo-image" />
            </Link>
            <div className="navbar-menu">
              {menuItems.map((item) => (
                <div
                  key={item.name}
                  className="menu-item"
                  onMouseEnter={() => setActiveMegaMenu(item.name)}
                  onMouseLeave={() => setActiveMegaMenu(null)}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <span className="menu-text">{item.name}</span>
                  {item.megaMenu.length > 0 && activeMegaMenu === item.name && (
                    <div className="mega-menu">
                      {item.megaMenu.map((column) => (
                        <div key={column.title} className="mega-menu-column">
                          <h3 className="mega-menu-title">{column.title}</h3>
                          <ul className="mega-menu-list">
                            {column.items.map((subItem) => (
                              <li key={subItem} className="mega-menu-item">
                                <a href="#" className="mega-menu-link">
                                  {subItem}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="navbar-right">
            <span className="icon-text">
              <Search className="icon" />
            </span>
            <span className="icon-text" onClick={handleUserIconClick}>
              <User className="icon" />
            </span>
            <span className="icon-text">
              <ShoppingBag className="icon" />
            </span>
          </div>
          <div className="mobile-menu-text">
            <span onClick={toggleMenu} className="icon-text">
              {isMenuOpen ? <X className="icon" /> : <Menu className="icon" />}
            </span>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="mobile-menu">
          <div className="mobile-menu-items">
            {menuItems.map((item) => (
              <div key={item.name}>
                <a
                  href="#"
                  className="mobile-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenuItemClick(item);
                  }}
                >
                  {item.name}
                </a>
                {item.megaMenu.map((column) => (
                  <div key={column.title}>
                    <span className="mobile-submenu-title">{column.title}</span>
                    {column.items.map((subItem) => (
                      <a key={subItem} href="#" className="mobile-submenu-item">
                        {subItem}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mobile-menu-icons">
            <button className="icon-button">
              <Search className="icon" />
            </button>
            <button className="icon-button">
              <User className="icon" />
            </button>
            <button className="icon-button">
              <ShoppingBag className="icon" />
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
